import Logo from "../img/MDR_logo_white_no_text.png";
import Notes from "../img/collections.svg";
import openEHR from "../img/openEHR.svg";
import { DMPUrl, MDRUrl, helpCenterUrl } from "./urls";

const DATAHUB_SERVICES = [
  {
    key: 1,
    id: "MDR",
    title: "Store your data securely at DataHub",
    icon: Logo,
    description:
      "Researchers need their data easily accessible, shareable and stored safely. Maastricht Data Repository can fulfil these needs by providing an easy to use data repository that is connected to several storage solutions. Maastricht Data Repository is your starting point for using the DataHub storage facilities. ",
    link: MDRUrl,
    linkTitle: "Maastricht Data Repository",
  },
  {
    key: 2,
    id: "DMP",
    title: "Start writing your Data Management Plan",
    icon: Notes,
    description:
      "The task of writing a Data Management Plan (DMP) might seem daunting at first. No worries. We got your back. Use our web-based DMP tool for creating, reviewing and sharing DMPs. Stick to the guidance and you’ll be done in no time.",
    link: DMPUrl,
    linkTitle: "Data Management Plan",
  },
  {
    key: 3,
    id: "HDP",
    title: "Securely share health data according to open standards",
    icon: openEHR,
    description:
      "Introducing a secure, vendor-neutral health data platform that adheres to open standards for sharing and engagement. Our platform collects, transforms, and ensures safe interaction with health data, utilizing internationally developed clinical models (openEHR) to provide a FAIR (Findable, Accessible, Interoperable, Reusable) EHR system. Our ultimate goal is to enhance care outcomes through seamless data interoperability and accessibility.",
    link: helpCenterUrl + "/support",
    linkTitle: "Contact DataHub for more information",
  },
];

export default DATAHUB_SERVICES;
